import React from 'react'
import Footer from '../../components/footer'
import Header from '../../components/header'
import SEO from '../../components/SEO'
import '../../styles/hollywood-event.less'
import Form from '../../components/form'
import { sendData } from '../../components/form-biz'
import { handleClick } from '../../templates/blog-singular'
const title = 'Beers with peers | Zenlayer'
// const loadText = require('src/utils').loadText
const TopBanner = () => (
  <div style={{ background: '#f5f9ff' }}>
    <div className="hollywood-event-topBanner">
      <div className="hollywood-event-topBanner-bg">
        <div className="headerContainer ">
          <Header logo="/logo-blue.svg" />
        </div>
        <div className="hollywood-event-topBanner-content">
          <div className="right">
            <div className="title">Beers with Peers</div>
            <div className="subtitle">#Hollywood</div>
            <div className="date">Sunday, October 16th, 2022</div>
            <img src="/hollywood-event/topBanner-pic2.svg" alt="icon" />
          </div>
          <div className="left">
            <img src="/hollywood-event/topBanner-pic.png" alt="icon" />
          </div>
        </div>
      </div>
    </div>
  </div>
)
const FormSec = () => (
  <div className="hollywood-event-form">
    <div className="hollywood-event-form-bg">
      <div className="hollywood-event-form-content">
        <div className="left">
          <div className="title">
            Join Us at the NANOG 86 Kick Off Reception in Hollywood!
          </div>
          <div className="label blue">RSVP for Beers with Peers</div>
          <div>
            Meet and mingle with hundreds of fellow industry IT leaders,
            including your peers from leading network, cloud, IT and data center
            service providers, while you enjoy complimentary cocktails, food and
            music at The Loews Hollywood.
          </div>
          <div className="label">Time & Location</div>
          <div>Sunday, October 16，6:00-9:00 PM</div>
          <div className="address">Loews Hotel Pool Terrace</div>
          <div>1755 N Highland Ave, Hollywood, California, 90028</div>
          <div className="link">
            <a href="https://www.loewshotels.com/hollywood-hotel">
              The Loews Hollywood
            </a>
          </div>
          <div className="label">Share this Event:</div>
          <div className="share">
            {['linkedin', 'twitter', 'facebook'].map((key) => (
              <img
                src={`/blog/${key}-h.svg`}
                alt={`${key}_share`}
                onClick={() => {
                  handleClick(
                    key,
                    encodeURI(window.location.href),
                    encodeURI(title)
                  )
                }}
              />
            ))}
          </div>
        </div>
        <div className="right">
          {/* <div className="right-title">{}</div> */}
          {/* <EbookPageForm source="ebook/game" onSuccess={() => {}} /> */}
          <Form
            type="hollywoodForm"
            onSuccess={({ reset }) => {
              reset()
            }}
            onSubmit={async (data) => {
              await sendData({
                data: { ...data },
                url: 'https://go.zenlayer.com/l/879902/2022-08-30/s49gx',
                source: `event/nanog-hollywood`,
              })
            }}
          />
        </div>
      </div>
    </div>
  </div>
)

export default function Home() {
  return (
    <div className="hollywood-event">
      <SEO
        // {...data.seo}
        description=""
        title={title}
        featuredImage="/featuredImage/zenlayer.jpg"
        noIndex={true}
      />
      <TopBanner />
      <FormSec />
      <Footer />
    </div>
  )
}
